/*!
 * Infinite Scroll v3.0.4
 * Automatically add next page
 *
 * Licensed GPLv3 for open source use
 * or Infinite Scroll Commercial License for commercial use
 *
 * https://infinite-scroll.com
 * Copyright 2018 Metafizzy
 */

( function( window, factory ) {
  // universal module definition
  /* globals define, module, require */
  if ( typeof define == 'function' && define.amd ) {
    // AMD
    define( [
      './core',
      './page-load',
      './scroll-watch',
      './history',
      './button',
      './status',
    ], factory );
  } else if ( typeof module == 'object' && module.exports ) {
    // CommonJS
    module.exports = factory(
      require('./core'),
      require('./page-load'),
      require('./scroll-watch'),
      require('./history'),
      require('./button'),
      require('./status')
    );
  }

})( window, function factory( InfiniteScroll ) {
  return InfiniteScroll;
});
